import { ApiPath } from '~/enum/apiPath'
import http from '~/http-common'
import { makeQueryStrings } from '~/libs/makeQueryStrings'
import type { StudentRes } from '~~/types/studentRes'
import type { UserId } from '~~/types/userId'

class GetStudentDatasService {
  post(data: string): Promise<StudentRes> {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      'Content-type': 'application/json; charset=utf-8',
      accept: 'application/json, text/javascript, */*; q=0.01',
      csrfToken: sessionStorage.getItem('token')!,
    }
    return http.post(ApiPath.GET_STUDENT_DATAS_POST, data, { headers: headers })
  }

  getDetailInfo(stuId: UserId): Promise<StudentRes> {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      accept: 'application/json, text/javascript, */*; q=0.01',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const param = {
      stuId: stuId,
    }
    const queryStrings: string = makeQueryStrings(param)
    return http.get(ApiPath.STUDENT_DETAIL_GET + queryStrings, { headers: headers })
  }
}

export default new GetStudentDatasService()
