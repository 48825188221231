const apiDayweek = new Map<string, string>([
  ['1', 'monday'],
  ['2', 'tuesday'],
  ['3', 'wednesday'],
  ['4', 'thursday'],
  ['5', 'friday'],
  ['6', 'saturday'],
  ['7', 'sunday'],
])
export default apiDayweek
