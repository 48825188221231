import { ApiPath } from '~/enum/apiPath'
import http, { convertFormData } from '~/http-common'
import type { StudentDetailRes } from '~~/types/studentDetailRes'
import type { UserId } from '~~/types/userId'

class StudentDatasService {
  /**
   * manager/F21018/updateへのPOSTを行う
   * @param memo 生徒情報の備考
   * @param dayweek 生徒の通塾日をカンマで結合したもの
   * @param stuId 生徒ID
   * @returns HttpResponseData
   */
  update = async (memo: string, dayweek: string, stuId: UserId, updDatime: string): Promise<StudentDetailRes> => {

    const path = ApiPath.STUDENT_DATAS_UPDATE
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const paramMap: Map<string, string> = new Map([
      ['memo',encodeURIComponent(memo)],
      ['dayweek',dayweek],
      ['stuId',stuId],
      ['updDatime',updDatime],
    ])
    const data = convertFormData(paramMap)

    return await http.post(path, data, { headers: headers })
  }
}

export default new StudentDatasService()
