import type { Student } from '~~/types/student'
import type { UserId } from '~~/types/userId'
import RegisterStudentDatasService from '~/services/StudentDatasService'
import type { F21017Params } from '~~/types/F21017Params'
import type { F21017Dto } from '~~/types/F21017Dto'
import type { StudentRes } from '~~/types/studentRes'
import { Schy } from '~/enum/schy'
import { Gender } from '~/enum/gender'
import { FstLoginFlg } from '~/enum/fstLoginFlg'
import { Dayweek } from '~/enum/dayweek'
import apiSchy from '~/const/apiSchy'
import apiGender from '~/const/apiGender'
import apiFstLoginFlg from '~/const/apiFstLoginFlg'
import apiDayweek from '~/const/apiDayweek'
import GetStudentDatasService from '~/services/GetStudentDatasService'
import GetStudentAbsStsService from '~/services/GetStudentAbsStsService'
import { getAge } from '~/libs/ageFormatter'
import { convertDivForFront } from '~/libs/convertDivForFront'
import { getDefaultAvatarPath } from '~/libs/getDefaultAvatarPath'
import { convertForApi } from '~/libs/convertDivForApi'
import { DateUtil } from '~/utils/date-util'
import { FreeTrialStatus } from '~/enum/freeTrialStatus'
import { memberAdmissionStatusCheck } from '~/libs/memberAdmissionStatusCheck'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const STUDENT_STORE_KEY = 'StudentStore'

export const useStudentStore = defineStore(STUDENT_STORE_KEY, () => {

  /**
   *  生徒情報一覧
   */
  const studentList = ref<Student[]>([])

  /**
   * ユーザIDを元に生徒情報を取得する処理
   */
  const getStudentByUsrId = (usrId: UserId) => {
    return studentList.value.find((student) => student.usrId === usrId)!
  }

  const getStudentListByUsrId = (usrId: UserId) => {
    return studentList.value.filter((student) => student.usrId === usrId)
  }

  const resetStudentList = () => {
    studentList.value.splice(0)
  }

  const setMailad = (stuId: string, mailad: string) => {
    getStudentByUsrId(stuId).mailad = mailad
  }

  // Studentデータを取得する関数
  const getStudentDatas = async (params: F21017Params) => {
    // APIから取得したデータをStudent型に格納
    const result: StudentRes = await GetStudentDatasService.post(JSON.stringify(params))
    if (result.data.code == 0) {
      const stuList: F21017Dto[] = result.data.stuList.filter(x => x.guardId !== null && x.flnmNmGuard !== null)
      sessionStorage.setItem('studentCount', result.data.count)
      stuList.forEach((element: F21017Dto) => {
        let fstLoginFlg = element.fstLoginFlg
        if (!fstLoginFlg) {
          fstLoginFlg = '2'
        }
        if (element.schyDiv == undefined) {
          return
        }
        const studentData: Student = {
          usrId          : element.stuId,
          name           : element.stuName,
          birthd         : element.birthd,
          birthdayText   : element.birthDayTxt,
          avatar         : getDefaultAvatarPath(element.photPath),
          schy           : Schy[convertDivForFront(element.schyDiv, apiSchy) as keyof typeof Schy],
          strSchy        : element.schyDiv,
          entryDt        : element.entryDt,
          age            : getAge(new DateUtil(element.birthd).date),
          orgId          : element.orgId,
          sch            : element.sch,
          guardId        : element.guardId,
          flnmNm         : element.flnmNm,
          flnmLnm        : element.flnmLnm,
          flnmKnNm       : element.flnmKnNm,
          flnmKnLnm      : element.flnmKnLnm,
          gender         : Gender[convertDivForFront(element.gendrDiv, apiGender) as keyof typeof Gender],
          memoCont       : element.memoCont === null ? '' : element.memoCont,
          admissionDate  : element.admissionDate,
          fstLoginFlg    : FstLoginFlg[convertDivForFront(fstLoginFlg, apiFstLoginFlg) as keyof typeof FstLoginFlg],
          mailad         : '',
          nameGuard      : element.nameGuard,
          flnmNmGuard    : element.flnmNmGuard,
          flnmLnmGuard   : element.flnmLnmGuard,
          flnmKnNmGuard  : element.flnmKnNmGuard,
          flnmKnLnmGuard : element.flnmKnLnmGuard,
          telnum         : element.telnum,
          urgTelnum      : element.urgTelnum,
          dayweek        : setDayweekDiv(element.dayweekDiv),
          entryFlg       : Number(element.entryFlg),
          tgtYmd         : element.tgtYmd,
          absSts         : element.absSts,
          id             : Number(element.id),
          updDatime      : element.updDatime,
          orgNm          : '',
          qrCod          : element.qrCod,
          memberCode     : element.memberCd,
          courseId       : element.courseId,
          courseNm       : element.courseDisplayName,
          courseAuthorizationCode: element.courseAuthorizationCode,
          englishAuthorizationFlg: element.englishAuthorizationFlg,
          freeTrialStatus: FreeTrialStatus[element.freeTrialStatus as keyof typeof FreeTrialStatus],
          admissionDiv   : element.admissionDiv,
          memberAdmissionStatus: memberAdmissionStatusCheck(FreeTrialStatus[element.freeTrialStatus as keyof typeof FreeTrialStatus], element.admissionDiv)
        }

        // リストにデータを追加
        studentList.value.push(studentData)
      })
    } else {
      Sentry.captureException(new Error('Failed to fetch student data'))
    }
  }

  // 生徒の入退室ステータスを再取得
  const updateStudentAbsSts = async () => {
    // APIから取得したデータをStudent型に格納
    const studentParams: F21017Params = {
      schoolYear: '',
      week: '',
      status: '',
    }
    const result: StudentRes = await GetStudentAbsStsService.post(JSON.stringify(studentParams))
    if (result.data.code == 0) {
      const stuList: F21017Dto[] = result.data.stuList
      // 入退室ステータスを更新
      studentList.value.forEach((elemStu: Student) => {
        for (const elemAbs of stuList) {
          // 同一生徒の情報を更新
          if (elemStu.id == Number(elemAbs.id)) {
            elemStu.absSts = elemAbs.absSts
            elemStu.entryDt = elemAbs.entryDt
            elemStu.entryFlg = Number(elemAbs.entryFlg)
            break
          }
        }
      })
      // entryFlg, entryDt, memberCode順にソート
      studentList.value.sort((a: Student, b: Student) => {
        if (a.entryDt == null && b.entryDt == null) {
          if (a.memberCode == null) {
            return 1
          } else if (b.memberCode == null) {
            return -1
          } else {
            return a.memberCode.localeCompare(b.memberCode)
          }
        } else if (a.entryDt == null) {
          return 1
        } else if (b.entryDt == null) {
          return -1
        } else {
          if (a.entryFlg !== b.entryFlg) {
            return a.entryFlg - b.entryFlg
          } else if (a.entryDt !== b.entryDt) {
            return new DateUtil(a.entryDt).date.valueOf() - new DateUtil(b.entryDt).date.valueOf()
          } else {
            if (a.memberCode == null) {
              return 1
            } else if (b.memberCode == null) {
              return -1
            } else {
              return a.memberCode.localeCompare(b.memberCode)
            }
          }
        }
      })
    } else {
      Sentry.captureException(new Error('生徒の入退室ステータスを再取得失敗'))
    }
  }

  const updateStudentAction = async (studentData: Student): Promise<boolean> => {
    const memo = studentData.memoCont
    const stuId = studentData.usrId
    const updDatime = studentData.updDatime
    // 通塾日
    getStudentByUsrId(stuId).dayweek = studentData.dayweek
    // メモ
    getStudentByUsrId(stuId).memoCont = memo

    let dayweekStr = ""
    for(let i = 0; i < studentData.dayweek.length; i++) {
      // enumのvalueからkeyを取得
      let dayweekKey = ''
      Object.entries(Dayweek).forEach(([key, value]) => {
        if (value == studentData.dayweek[i]) {
          dayweekKey = key
          return
        }
      });
      dayweekStr += convertForApi(dayweekKey, apiDayweek)
      if(i != studentData.dayweek.length - 1) {
        dayweekStr += ','
      }
    }

    return await RegisterStudentDatasService.update(memo, dayweekStr, stuId, updDatime).then(result => {
      if(result.data.code != 0) {
        return false
      }
      // データ更新後、ストアのupdDatime更新
      getStudentByUsrId(stuId).updDatime = result.data.updDatime
      return true
    })
  }

  const setFreeTrialStatus = (stuId: string, updateDate: string) => {
    const targetStudent = studentList.value.find((student) => student.usrId === stuId)!
    targetStudent.updDatime = updateDate
    targetStudent.freeTrialStatus = FreeTrialStatus.TRIALING
  }
  return {
    studentList,
    getStudentByUsrId,
    getStudentListByUsrId,
    resetStudentList,
    setMailad,
    getStudentDatas,
    updateStudentAbsSts,
    updateStudentAction,
    setFreeTrialStatus,
  }
}, {
  persist: {
    storage: sessionStorage,
  }
})

// 曜日を配列に変換
const setDayweekDiv = (deyweekDiv: string) => {
  const dayweekArray: Dayweek[] = []
  if (deyweekDiv != null && deyweekDiv != '') {
    const deyweekDivArray: string[] = deyweekDiv.split(',')
    deyweekDivArray.forEach(str => {
      dayweekArray.push(Dayweek[convertDivForFront(str, apiDayweek) as keyof typeof Dayweek])
    })
  } else {
    Object.entries(Dayweek).forEach(([key, value]) => {
      if (key != 'all') {
        dayweekArray.push(value)
      }
    })
  }
  return dayweekArray
}
