import { ApiPath } from '~/enum/apiPath'
import http from '~/http-common'
import type { StudentRes } from '~~/types/studentRes'

class GetStudentAbsStsService {
  post(data: string): Promise<StudentRes> {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      'Content-type': 'application/json; charset=utf-8',
      accept: 'application/json, text/javascript, */*; q=0.01',
      csrfToken: sessionStorage.getItem('token')!,
    }
    return http.post(ApiPath.GET_STUDENT_ABS_STS_POST, data, { headers: headers })
  }
}

export default new GetStudentAbsStsService()
