export enum Dayweek {
  all = 'すべて選択',
  monday = '月',
  tuesday = '火',
  wednesday = '水',
  thursday = '木',
  friday = '金',
  saturday = '土',
  sunday = '日'
}
